:global(:root) {
  --fc-border-color: rgba(21, 21, 21, 0.2);
  --fc-button-active-bg-color: white;
  --fc-button-active-border-color: transparent;
  --fc-button-bg-color: white;
  --fc-button-border-color: transparent;
  --fc-button-hover-bg-color: white;
  --fc-button-hover-border-color: transparent;
  --fc-button-text-color: #151515;
  --fc-event-text-color: #151515;
  --fc-page-bg-color: #c4dbea;
  --fc-small-font-size: 12px;
  --rt-opacity: 1;
}

.tooltip {
  @apply rounded-lg bg-white text-brand-1;
  transform: translateY(8px);
  border: 1px solid #ededee;
  box-shadow: 8px 16px 24px rgba(21, 21, 21, 0.08);
  font-size: 14px;
  padding: 12px;
  z-index: 1000;
}

:global(.fc .fc-toolbar-title) {
  @apply font-bold;
  font-size: 24px;
}

:global(.fc-toolbar-chunk .fc-button) {
  @apply rounded-md border-none;
}

:global(.fc-toolbar-chunk .fc-button:focus),
:global(.fc-toolbar-chunk .fc-button-primary:not(:disabled):active:focus) {
  box-shadow: none;
}

:global(.fc-toolbar-chunk .fc-prev-button),
:global(.fc-toolbar-chunk .fc-next-button) {
  @apply p-0 inline-flex items-center justify-center;
  width: 32px;
  height: 32px;
}

:global(.fc-toolbar-chunk .fc-today-button) {
  line-height: 32px;
  padding: 0 16px;
}

:global(.fc .fc-button .fc-icon) {
  font-size: 1.2em;
  vertical-align: unset;
}

:global(.fc-theme-standard .fc-scrollgrid) {
  border: none;
}

:global(.fc-day),
:global(.fc .fc-daygrid-day.fc-day-today) {
  @apply bg-white;
}

:global(.fc-day-sat),
:global(.fc-day-sun),
:global(.fc .fc-daygrid-day.fc-day-today.fc-day-sat),
:global(.fc .fc-daygrid-day.fc-day-today.fc-day-sun) {
  background-color: #ededee;
}

:global(.fc-event) {
  border-radius: 4px;
}

:global(.fc-event-time) {
  display: none;
}

:global(.fc-event-main) {
  padding: 0 6px;
}

:global(.fc .fc-daygrid-day-number) {
  @apply flex items-start justify-center p-0;
  margin: 6px;
  width: 32px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
}

:global(.fc .fc-day-today .fc-daygrid-day-number) {
  @apply text-white;
  background-color: #f93e3e;
  border-radius: 8px;
}

:global(.fc .fc-col-header-cell) {
  @apply bg-brand-1 text-white text-right font-normal capitalize;
  height: 52px;
}

:global(.fc .fc-col-header-cell:first-child) {
  @apply rounded-tl-lg;
}

:global(.fc .fc-col-header-cell:last-child) {
  @apply rounded-tr-lg;
}

:global(.fc .fc-col-header-cell .fc-scrollgrid-sync-inner) {
  @apply h-full flex items-center justify-end;
  padding-right: 12px;
}

:global(.fc-scrollgrid-section > th),
:global(.fc-scrollgrid-section > td) {
  border-right: none;
  border-bottom: none;
}

:global(.fc-daygrid-body) {
  @apply bg-brand-1 rounded-b-lg;
}

:global(.fc-daygrid-body tr:first-child td:first-child) {
  @apply rounded-tl-lg border-t-0;
}

:global(.fc-daygrid-body tr:first-child td:last-child) {
  @apply rounded-tr-lg;
}

:global(.fc-daygrid-body tr:last-child td:first-child) {
  @apply rounded-bl-lg;
}

:global(.fc-daygrid-body tr:last-child td:last-child) {
  @apply rounded-br-lg;
}

:global(.fc .fc-scrollgrid table) {
  border-bottom-style: hidden;
}
