.container {
  background-image: url('../assets/images/background.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media (min-width: 768px) {
  /* 1.353 is ratio width to height of original background image */
  .container {
    background-image: linear-gradient(
        to right,
        rgba(21, 21, 21, 0) 0%,
        rgba(21, 21, 21, 0) calc(100vh * 1.353 - 50px),
        rgba(21, 21, 21, 0.8) calc(100vh * 1.353 - 25px),
        rgba(21, 21, 21, 1) calc(100vh * 1.353)
      ),
      url('../assets/images/background.webp');
    background-repeat: no-repeat, no-repeat;
    background-position: right center, left top;
    background-size: 100%, auto 100%;
  }
}
