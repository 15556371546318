@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-brand-1;
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.input:focus {
  @apply border-brand-3;
  box-shadow: 0px 0px 8px rgba(96, 146, 179, 0.52);
}

.input:invalid,
.input--invalid,
.input--invalid:focus {
  @apply border-red;
}

.input--code {
  @apply text-[24px] leading-none text-center;
}

.input--search {
  background-image: url('./assets/icons/search.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
}

.select {
  background-image: url('./assets/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.select:invalid {
  @apply text-brand-1/40 border-brand-2;
}

.select--invalid,
.select--invalid:focus {
  @apply border-red !important;
}

.table {
  @apply bg-white w-full;
}

.table tbody {
  @apply relative;
}

.table.long tbody:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  width: 100%;
  height: 5em;
}

.table thead tr th {
  @apply text-brand-1/40 font-normal py-[12px] border-b border-brand-1/10;
}

.table tbody tr.hoverable {
  @apply cursor-pointer;
}

.table tbody tr.hoverable:hover td {
  @apply bg-brand-3/[0.34];
}

.table tbody tr td {
  @apply border-b border-brand-1/10 transition-all;
  height: 68px;
}

.table tbody tr:last-child td {
  @apply border-none;
}
